<template>
  <header>
    <parula-header />
  </header>

  <div>
    <slot />
  </div>

  <footer>
    <parula-subscription />
    <parula-footer />
  </footer>
</template>

<script>
import ParulaHeader from "../components/menu/ParulaHeader.vue";
import ParulaSubscription from "../components/menu/ParulaSubscription.vue";
import ParulaFooter from "../components/menu/ParulaFooter.vue";

export default {
  components: {
    ParulaHeader,
    ParulaSubscription,
    ParulaFooter,
  },
};
</script>

<style scoped>
header {
  position: sticky;
  top: 0px;
  z-index: 30;
}
</style>
