<template>
  <main>
    <component :is="layout">
      <slot />
    </component>
  </main>
</template>

<script>
import ParulaLayoutDefault from "./ParulaLayoutDefault";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ParulaLayout",

  setup() {
    const layout = shallowRef(ParulaLayoutDefault);
    const route = useRoute();
    watch(
      () => route.meta,
      async (meta) => {
        try {
          const component = await require(`@/layouts/${meta.layout}.vue`);
          layout.value = component?.default || ParulaLayoutDefault;
        } catch (e) {
          layout.value = ParulaLayoutDefault;
        }
      }
    );
    return { layout };
  },
};
</script>

<style scoped>
main {
  min-height: 100vh;
}
</style>
