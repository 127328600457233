/*** 로그인 프로세스 정리
1. 세션 존재여부 확인
2. 지값주소 가져오기
3. 지갑주소가 등록되어 있는지 확인
4. 분기처리
    - 회원가입
    - 로그인
    - 로그인완료

회원가입 및 로그인 이후, 로그인정보를 로컬스토리지에 저장
- 최종로그인시간
- 주소
- 닉네임
- 아바타
 */

export default {
  checkSession() {
    return true;
  },

  clear() {
    localStorage.setItem("__session__", null);
    localStorage.setItem("__token__", null);
  },

  setUserId(user_id) {
    let session = this.loadSession();
    session.userId = user_id;
    this.saveSession(session);
  },

  getUserId() {
    let session = this.loadSession();
    return session.userId;
  },

  setNickname(nickname) {
    let session = this.loadSession();
    session.nickname = nickname;
    this.saveSession(session);
  },

  setAccount(account) {
    let session = this.loadSession();
    session.wallet = account;
    this.saveSession(session);
  },

  getNickname() {
    let session = this.loadSession();
    return session.nickname;
  },

  getWallet() {
    let session = this.loadSession();
    return session.wallet;
  },

  loadSession() {
    try {
      let session = JSON.parse(localStorage.getItem("__session__"));
      return session ? session : {};
    } catch (e) {
      return {};
    }
  },

  saveSession(session) {
    localStorage.setItem("__session__", JSON.stringify(session));
  },

  setItems(items = { platform, wallet, nickname, avatar, userId, msg, sig }) {
    const now = new Date();
    let session = this.loadSession();
    session.lastTime = now;

    Object.keys(items).forEach((k) => {
      if (items[k]) session[k] = items[k];
    });

    const stringed_session = JSON.stringify(session);
    localStorage.setItem("__session__", stringed_session);
  },

  setToken(token) {
    localStorage.setItem("__token__", token);
  },

  getToken() {
    try {
      var token = localStorage.getItem("__token__");
      return token;
    } catch (e) {
      return null;
    }
  },

  logout() {
    this.clear();
  },

  valid(_addr) {
    if (_addr == undefined) {
      return false;
    }
    const session = this.loadSession();
    const last = session.lastTime;
    if (
      session.address &&
      _addr.toUpperCase() !== session.address.toUpperCase()
    ) {
      return false;
    }
    if (this.isExpired(last)) {
      return false;
    }
    return true;
  },

  isExpired(curDate) {
    let today, resultDate;
    today = new Date();
    resultDate = new Date(curDate);
    let timeGap = (today - resultDate) / (60 * 60 * 1000);

    // Time (minutes * seconds * millisecond)
    if (timeGap <= 24) {
      return false;
    } else {
      return true;
    }
  },
};
