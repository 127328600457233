import axios from "axios";
export default {
  // blockchains
  getBlockchainList() {
    return this.api().get(`/chain/list`);
  },

  getBlockchainById(id) {
    return this.api().get(`/chain/${id}`);
  },

  // Assets
  getAssets() {
    return this.api().get(`/asset/list`);
  },

  getAssetsByCategory(network, category) {
    return this.api().get(`/asset/listbyc/${network}/${category}`);
  },

  getAssetsByType(network, type) {
    return this.api().get(`/asset/listbyt/${network}/${type}`);
  },

  // order
  orderDisplayList(page, pageSize, obj) {
    let filterBy, assetSymbolBy, search, mintAfter, mintBefore, categoryBy;
    let sortBy = "newest";
    let ignoreEndDate = true;

    if (obj) {
      obj.type ? (filterBy = obj.type) : (filterBy = undefined);
      obj.token ? (assetSymbolBy = obj.token) : (assetSymbolBy = undefined);

      obj.search ? (search = obj.search) : (search = undefined);
      categoryBy = obj.category ? obj.category : undefined;

      if (obj.date) {
        obj.date.mint_after
          ? (mintAfter = obj.date.mint_after)
          : (mintAfter = undefined);
        obj.date.mint_before
          ? (mintBefore = obj.date.mint_before)
          : (mintBefore = undefined);
      }

      obj.sort ? (sortBy = obj.sort) : (sortBy = "newest");
    }

    console.log(
      "ORDER_DISPLAY_LIST => ",
      filterBy,
      sortBy,
      assetSymbolBy,
      search,
      mintAfter,
      mintBefore,
      ignoreEndDate,
      categoryBy
    );

    return this.api().get("/order/display/list", {
      params: {
        page: page,
        pageSize: pageSize,
        filterBy,
        sortBy,
        assetSymbolBy,
        search,
        mintAfter,
        mintBefore,
        ignoreEndDate,
        categoryBy,
      },
    });
  },

  orderDisplayListStateBy(stateBy) {
    return this.api().get(`/order/display/list`, {
      params: {
        page: 1,
        pageSize: 20,
        sortBy: "mostPopular",
        stateBy,
      },
    });
  },

  orderGet(id) {
    return this.api().get(`/order/${id}`);
  },

  // user

  userByAddress(address) {
    return this.api().get(`/user?byAddress=${address}`);
  },

  userById(id) {
    return this.api().get(`/user/byid/${id}`);
  },

  userByNickname(nickname) {
    return this.api().get(`/user/${nickname}`);
  },

  userLikedProducts(nickname) {
    return this.api().get(`/user/${nickname}/liked`);
  },

  userPurchaseHistory(nickname, sortBy) {
    return this.api().get(`/user/${nickname}/purchased`, {
      params: {
        sortBy,
      },
    });
  },

  userCollection(nickname, sortBy) {
    return this.api().get(`/user/${nickname}/collection`, {
      params: {
        sortBy,
      },
    });
  },

  userBidHistory(nickname, sortBy) {
    return this.api().get(`/user/${nickname}/bids`, {
      params: {
        sortBy,
      },
    });
  },

  userLikedHistory(nickname, sortBy) {
    return this.api().get(`/user/${nickname}/liked`, {
      params: {
        sortBy,
      },
    });
  },

  userUpdate(nickname, avatar, avatar_min) {
    const param = {
      nickname: nickname,
      avatar: avatar,
      avatar_min: avatar_min,
    };
    return this.api().put(`/user/${nickname}`, param);
  },

  artworks(user_id) {
    return this.api().get(`/portfolio/list/${user_id}`);
  },

  bidGetUserList(order_id) {
    return this.api().get(`/bid/withuser/${order_id}`);
  },

  // product
  productHistory(id) {
    return this.api().get(`/product/${id}/history`);
  },

  productGet(id) {
    return this.api().get(`/product/${id}`);
  },

  // get user's artworks
  // getUserArtworkInfo(nickname, type, sortBy, page, pageSize) {
  //   // type: liked, purchased, collection, bids
  //   return this.api().get(`/user/${nickname}/${type}`, {
  //     params: {
  //       page,
  //       pageSize,
  //       sortBy,
  //     },
  //   });
  // },

  getSaleableProducts(
    page,
    pageSize,
    key,
    value,
    state,
    startDate,
    endDate,
    ownerAddress,
    auction
  ) {
    console.log("ownerAddress => ", ownerAddress);
    if (!value)
      // value 없으면 키도 의미 없음
      key = null;
    return this.api().get(`/owner/product/search`, {
      params: {
        page,
        pageSize,
        key,
        value,
        state,
        startDate,
        endDate,
        ownerAddress,
        auction,
      },
    });
  },

  getUserArtworkInfo(nickname, type, sortBy, page, pageSize) {
    // type: liked, purchased, collection, bids
    return this.api().get(`/user/${nickname}/${type}`, {
      params: {
        page,
        pageSize,
        sortBy,
      },
    });
  },

  getUserSalesInfo(sellerNickname, filterBy, stateBy) {
    return this.api().get(
      // filterBy: normalOnly, auctionOnly
      `/order/display/list?sellerNickname=${sellerNickname}&filterBy=${filterBy}&stateBy=${stateBy}`
    );
  },

  // get history on activity
  getHistoryList(page, pageSize, filterBy) {
    return this.api().get(`/history/list?page=${page}&pageSize=${pageSize}`, {
      params: { filterBy },
    });
  },

  //
  pricing() {
    return this.api().get(`/util/pricing`);
  },

  contact(mail, method) {
    return this.api().post(`/contact/${method}`, {
      mail,
    });
  },

  // cms
  getSpotlightById(id) {
    return this.api().get(`/spotlight/${id}`);
  },

  getLatestSpotlight() {
    return this.api().get(`/spotlight-latest`);
  },

  // owners
  getOwners(id) {
    return this.api().get(`/owners/${id}`);
  },

  // product
  productJoinAsset(id) {
    return this.api().get(`/product/${id}/asset`);
  },

  // wallet

  api() {
    return axios.create({
      baseURL: process.env.VUE_APP_OPENAPI_URL,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
};
