<template>
  <div id="app">
    <parula-layout>
      <router-view />
    </parula-layout>
  </div>
</template>

<script>
export default {
  name: "app",

  watch: {
    $route() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
* {
  /* font */
  font-family: "Inter", sans-serif;
  color: #262626;
}
</style>
