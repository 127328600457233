import { providers} from "ethers";
import connect from "./index";
import { provider } from "../../walletConnect/provider";

const connectWalletConnect = async (msgPrefix) => {
  let signedMessage;
  let msgParams;

  try {
    const { state } = connect();
    //  Enable session (triggers QR Code modal)
    console.log("##connectWalletConnect", "connectWalletConnect", "Enable session for QR code modal", state, provider);

    await provider.enable();
    // provider.qrcodeModal.open(provider.connector.uri, { bridge: provider.bridge })

    const web3Provider = new providers.Web3Provider(provider);

    const signer = await web3Provider.getSigner();
    const address = await signer.getAddress();

    state.status = true;
    state.address = address;
    state.chainId = await provider.request({ method: "eth_chainId" });
    console.log(state.chainId);

    //-- check sign()
    let expireDate = new Date();
    let strMethod = "personal_sign";

    // expire date add 1day
    expireDate.setDate(expireDate.getDate() + 1);
    msgParams = `${msgPrefix} ${address} ${Date.parse(expireDate)}`;

    if(web3Provider.provider.wc) {
      signedMessage = await web3Provider.provider.send(strMethod, [msgParams, address]);
    } else {
      signedMessage = await signer.signMessage(msgParams);
    }

    //!--
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      console.log("disconnected");
      state.status = false;
      state.address = "";
      localStorage.removeItem("userState");
      localStorage.removeItem("walletconnect");
    });

    provider.on("accountsChanged", (accounts) => {
       if (accounts.length > 0) {
        state.address = accounts[0];
      }
    });

    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
      state.chainId = chainId
    });
  } catch (error) {
    console.log(error);

    localStorage.removeItem("userState");
    localStorage.removeItem("walletconnect");    
  }

  return {m:msgParams, s:signedMessage};
};

export default connectWalletConnect;
