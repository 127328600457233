import detectEthereumProvider from "@metamask/detect-provider";

// 메타마스크 설치 여부 확인
async function checkMetamaskInstallation() {
  const provider = await detectEthereumProvider();

  return provider === null ? false : true;
}

// 현재 메타마스크에 연결되어 있는 지갑 주소 가져오기
async function getCurrentMetamaskAccount() {
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res) => {
        resolve(res[0]);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

// 현재 메타마스크에 연결되어 네트워크 아이디 가져오기
async function getCurrentMetamaskNetworkId() {
  return new Promise((resolve, reject) => {
    const { chainId } = window.ethereum;

    if (chainId) {
      resolve(Number(chainId));
    }

    reject("Can not find network id.");
  });
}

export {
  checkMetamaskInstallation,
  getCurrentMetamaskAccount,
  getCurrentMetamaskNetworkId,
};
