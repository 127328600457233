import axios from "axios";
import sessionServices from "./session.service";
// import { notify } from "@kyvg/vue3-notification";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_OPENAPI_AUTH_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = sessionServices.getToken();
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// axiosInstance.interceptors.response.use(
//   (config) => {
//     console.log("axiosInstance.interceptors.response", config);
//     return config;
//   },
//   (error) => {
//     console.log(
//       "axiosInstance.interceptors.response error",
//       error,
//       error.response
//     );
//     notify({ type: "error", text: error });
//     // return Promise.reject(error);
//   }
// );

export default {
  // Wallet
  //회원가입
  walletRegist(name, nickname, mail, stage, platform, account, msg, sig) {
    const params = {
      name,
      nickname,
      mail,
      stage,
      platform,
      account,
      auth: {
        signer: account,
        msg,
        sig,
      },
    };
    console.log("param:", params);
    return axiosInstance.post(`/wallet/regist`, params);
  },

  walletDoubleCheck(address) {
    //지갑 중복체크
    return axiosInstance.get(`/wallet/check/${address}`);
  },

  walletDuplicateCheck(mail, nickname) {
    // 메일 및 닉네임 죽봉체크 - 'wallet'서비스에 있어서 여기 있음
    return axiosInstance.get(`/wallet/duplicate/check/${mail}/${nickname}`);
  },

  walletResendMail(account, stage, msg, sig) {
    const params = {
      account: account,
      stage: stage,
      auth: {
        signer: account,
        msg: msg,
        sig: sig,
      },
    };
    console.log("param:", JSON.stringify(params));
    return axiosInstance.post(`/wallet/resend`, params);
  },

  emailConfirm(id, secret) {
    return axiosInstance.get(`/mail/confirm/${id}/${secret}`);
  },

  //고객 상세페이지에서 지갑등록
  insertAccount(
    platform,
    account,
    user_id,
    alias,
    tag,
    auth_signer,
    auth_msg,
    auth_sig,
    msg,
    sig
  ) {
    const params = {
      platform: platform,
      account: account,
      user_id: user_id,
      alias: alias,
      tag: tag,
      auth: {
        signer: auth_signer,
        msg: auth_msg,
        sig: auth_sig,
      },
      verify: {
        msg: msg,
        sig: sig,
      },
    };
    console.log("params:", params);
    console.log("string:", JSON.stringify(params));
    return axiosInstance.post(`/wallet/add`, params);
  },

  walletLogin(platform, account, msg, sig) {
    console.log("services: ", platform, account, msg, sig);

    // TODO 이게 의미가 있나?
    const params = {
      platform: platform,
      account: account,
      auth: {
        signer: account,
        msg: msg,
        sig: sig,
      },
    };
    return axiosInstance.post(`/wallet/login`, params);
  },

  // walletUpdate(account, alias, tag, signer, msg, sig) {
  //   return axiosInstance.put(`/wallet/update/${id}`, {
  //     account: account,
  //     alias: alias,
  //     tag: tag,
  //     auth: {
  //       signer: signer,
  //       msg: msg,
  //       sig: sig,
  //     },
  //   });
  // },
  // walletList(user_id) {
  //   return axiosInstance.get(`/wallet/${user_id}/wallets`);
  // },

  walletList(user_id) {
    return axiosInstance.get(`/${user_id}/wallets`);
  },
  // walletDelete() {
  //   return axiosInstance.delete(`/wallet/delete/${id}/${account}/${msg}/${sig}`);
  // },

  // Like

  productLike(target_type, target_id, user_id) {
    return axiosInstance.post(`/like`, {
      target_type,
      target_id,
      user_id,
    });
  },

  productDislike(target_type, target_id, user_id) {
    console.log(
      "target_type, target_id, user_id ==> ",
      target_type,
      target_id,
      user_id
    );
    return axiosInstance.post(`/dislike`, {
      target_type,
      target_id,
      user_id,
    });
  },

  productDoeslike(target_type, target_id, user_id) {
    console.log(
      "target_type, target_id, user_id ==> ",
      target_type,
      target_id,
      user_id
    );
    return axiosInstance.get(
      `/doeslike?target_type=${target_type}&target_id=${target_id}&user_id=${user_id}`
    );
  },

  // bid
  bidCreate(
    order_id,
    bidder_id,
    order_rawdata,
    order_signature,
    order_wallet,
    asset_id,
    price
  ) {
    return axiosInstance.post(`/bid`, {
      order_id,
      bidder_id,
      order_rawdata,
      order_signature,
      order_wallet,
      asset_id,
      price,
    });
  },

  // product
  insert(
    name,
    description,
    total_supply,
    royalties,
    image,
    thumbnail,
    main_content_id,
    main_content_type,
    sub_content_id,
    sub_content_type,
    asset_id,
    collection_id,
    contract_type,
    state,
    creator_id,
    owner_id,
    owner_wallet_id,
    init_mint_wallet,
    init_owner_wallet,
    token_id,
    metadata_version,
    metadata_url,
    retTx,
    category
  ) {
    console.log(
      name,
      description,
      total_supply,
      royalties,
      image,
      thumbnail,
      main_content_id,
      main_content_type,
      sub_content_id,
      sub_content_type,
      asset_id,
      collection_id,
      contract_type,
      state,
      creator_id,
      owner_id,
      owner_wallet_id,
      init_mint_wallet,
      init_owner_wallet,
      token_id,
      metadata_version,
      metadata_url,
      retTx,
      category
    );
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/product", {
          name: name,
          description: description,
          total_supply: total_supply,
          royalties: royalties,
          image: image,
          thumbnail: thumbnail,
          main_content_id: main_content_id,
          main_content_type: main_content_type,
          sub_content_id: sub_content_id,
          sub_content_type: sub_content_type,
          asset_id: asset_id,
          collection_id: collection_id,
          contract_type: contract_type,
          state: state,
          creator_id: creator_id,
          owner_id: owner_id,
          owner_wallet_id: owner_wallet_id,
          init_mint_wallet: init_mint_wallet,
          init_owner_wallet: init_owner_wallet,
          token_id: token_id,
          metadata_version: metadata_version,
          metadata_url: metadata_url,
          transaction_hash: retTx.transactionHash,
          category: category,
        })
        .then((res) => {
          // console.log("res ==> ", res);
          resolve(res.data);
        })
        .catch((err) => {
          // console.log("err ==>", err);
          reject(err);
        });
    });
  },

  // order
  orderSold(
    id,
    buyer_id,
    sold_price,
    total_price,
    sold_price_usd,
    sold_price_native,
    quantity,
    toWallet,
    retTx
  ) {
    return axiosInstance.put(`/order/${id}/sold`, {
      buyer_id,
      sold_price,
      total_price,
      sold_price_usd,
      sold_price_native,
      quantity,
      toWallet,
      transaction_hash: retTx.transactionHash,
    });
  },

  createOrder(params) {
    // console.log("params ::::", params);
    return axiosInstance.post(`/order`, params);
  },

  updateState(id, order_state) {
    return axiosInstance.put(`/order/${id}/state`, {
      order_state, // 'pending', 'onSales', 'hold', 'sold', 'closed', 'onMatch', 'matchFail'
    });
  },

  getJoinBidList(page, pageSize, params, sellerId) {
    return axiosInstance.get(`/order/joinbid/list/?seller_id=${sellerId}`, {
      page,
      pageSize,
      params,
    });
  },

  getBidListWithAsset(order_id) {
    return axiosInstance.get(`/bid/withasset/${order_id}`);
  },

  // mailing
  soldMail(params) {
    return axiosInstance.post(`/mailing/sold`, {
      params,
    });
  },

  bidMail(params) {
    return axiosInstance.post(`/mailing/bid`, {
      params,
    });
  },

  // api() {
  //   return axios.create({
  //     baseURL: process.env.VUE_APP_OPENAPI_AUTH_URL,
  //     withCredentials: false,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       "Authorization": sessionServices.getToken()
  //     },
  //   });
  // },
};
