import { createApp } from "vue";

import App from './App.vue'
import router from './router'
import ParulaLayout from '@/layouts/ParulaLayout'
import "../src/app.css";

import Notifications from "@kyvg/vue3-notification";
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'

// 툴팁 커스텀 옵션
// import { TooltipOptions } from '@adamdehaven/vue-custom-tooltip'
// app.use(VueCustomTooltip)

// const opt: TooltipOptions = {
//     name: 'VueCustomTooltip',
//     color: '#fff',
//     background: '#000',
//     borderRadius: 100,
//     fontWeight: 400,
// }



import mitt from 'mitt'

const emitter = mitt();

const app = createApp(App);
app.use(Notifications);
app.use(VueCustomTooltip, {
    name: 'VueCustomTooltip',
    color: '#fff',
    background: '#262626',
    borderRadius: 0,
    fontWeight: 400,
})

app.use(router);
app.component('ParulaLayout', ParulaLayout);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
