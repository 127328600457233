<template>
  <header>
    <parula-header />
  </header>

  <div class="adjustment">
    <slot />
  </div>

  <footer>
    <parula-subscription />
    <parula-footer />
  </footer>
</template>

<script>
import ParulaHeader from "../components/menu/ParulaHeader.vue";
import ParulaSubscription from "../components/menu/ParulaSubscription.vue";
import ParulaFooter from "../components/menu/ParulaFooter.vue";

export default {
  components: {
    ParulaHeader,
    ParulaSubscription,
    ParulaFooter,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_layout.scss";

header {
  position: sticky;
  top: 0px;
  z-index: 30;
}

.adjustment {
  margin: auto;
  @include sm {
    width: 90%;
  }

  @include md {
    width: 80%;
  }

  @include lg {
    width: 80%;
  }

  @include xl {
    width: calc(100% - 288px);
  }

  @include xxl {
    width: 1440px;
  }
}

// @media screen and (min-width: 1440px) {
//   .adjustment {
//     width: calc(100% - 288px);
//     margin: auto;
//   }
// }

// @media screen and (min-width: 1728px) {
//   .adjustment {
//     width: 1440px;
//     margin: auto;
//   }
// }
</style>
