<template>
  <div
    class="layout__modal fixed inset-0 z-50"
    :class="{ modal__visible: visible, modal__invisible: !visible }"
  >
    <div
      class="fixed inset-0 bg-black bg-opacity-70 transition-opacity parula__text-white"
    >
      <div class="layout__modal__container">
        <div class="w-28 h-28">
          <img src="@/assets/img/cilo_loading.gif" alt="loading" />
        </div>
        <span class="mt-4">Please Wait...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },

  methods: {
    open() {
      this.visible = true;
    },

    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/_text.scss";

.modal__visible {
  display: block;
}
.modal__invisible {
  display: none;
}

.layout__modal {
  min-height: 100vh;
  .layout__modal__container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @extend %text__align;
  }
}

@include less-than-md {
  .layout__modal__container {
    width: 80vw;
    padding: 1rem 0px;
  }
}

@include more-than-md {
  .layout__modal__container {
    width: 60vw;
    padding: 2rem 0px;
  }
}
</style>
