<template>
  <footer class="layout__footer">
    <section>
      <div class="md:grid grid-cols-5 text-center md:text-left">
        <div class="flex flex-col col-span-3 gap-y-5 items-center md:items-start md:justify-between">
        <img class="img__max-size w-60 sm:w-96" src="@/assets/img/ciloxnbc.png"/>
          <div class="w-40">
            <a class="flex justify-center md:justify-start">
              <div class="w-40 mb-4">
                <!-- <svg
                  version="1.1"
                  id="parula__logo"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 880 164"
                  style="enable-background:new 0 0 880 164;"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M58,2.9c34.2,0,51.5,26.5,51.5,53.1S92.3,108.8,58,108.8H23.1v52.8H0V2.9H58z M58,86.1c19.3,0,28.8-15,28.8-29.9
                    C86.8,41,77.3,26,58,26H23.1v60.1L58,86.1z"
                    />
                    <path
                      d="M220,114.9h-56.9c-6.1,14.5-14.1,33.6-19,46.7h-24.7L183,0.8h16.8l63.7,160.8H239L220,114.9z M171.7,93.3h39.4l-19.5-56
                    L171.7,93.3z"
                    />
                    <path
                      d="M328.6,81.1h26.8c19.3,0,28.8-13.8,28.8-27.7c0-13.6-9.5-27.4-28.8-27.4h-35.1v135.6h-22.7V2.9h57.8
                    c34.5,0,51.7,25.4,51.7,50.6c0,22.4-13.8,43.8-41,48.5l50.1,59.6h-29.7L328.6,92V81.1z"
                    />
                    <path
                      d="M450,114V3.1h23.1v111.1c0,17.5,18.8,26.1,35.6,26.1c16.6,0,35.4-8.8,35.4-26.3V3.1h23.1v111.1c0,32.2-31.3,49-58.5,49
                    C480.8,163.2,450,146.4,450,114z"
                    />
                    <path d="M640,138.7h74.6v22.9h-97.5V3.1H640L640,138.7z" />
                    <path
                      d="M836.5,114.9h-56.9c-6.1,14.5-14.1,33.6-19,46.7h-24.7L799.5,0.8h16.8L880,161.6h-24.5L836.5,114.9z M788.2,93.3h39.4
                    l-19.5-56L788.2,93.3z"
                    />
                  </g>
                </svg> -->
              </div>
            </a>
            <!-- <svg
              version="1.1"
              id="레이어_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 880 55"
              style="enable-background:new 0 0 880 55;"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M7.6,8.5c4.7-4.8,11.2-7.9,18.4-7.9c12.2,0,21.8,7.6,24.9,19.7h-7.6c-2.8-8-9.4-12-17.2-12c-5.1,0-9.8,2.2-13.1,5.7
                    c-3.4,3.5-5.5,8.2-5.5,13.7c0,5.3,2.1,10.1,5.5,13.6c3.4,3.5,8,5.7,13.1,5.7c8.1,0,14.9-4.6,17.7-12.7h7.5
                    c-3,12.1-12.8,20.4-25.2,20.4c-7.2,0-13.8-3-18.4-7.9C3,41.8,0,35,0,27.7C0,20.1,3,13.4,7.6,8.5z"
                />
                <path
                  d="M80.9,27.1h9c6.5,0,9.7-4.7,9.7-9.3c0-4.6-3.2-9.2-9.7-9.2H78.1v45.6h-7.6V0.8h19.4c11.6,0,17.4,8.5,17.4,17
                    c0,7.5-4.7,14.7-13.8,16.3l16.8,20h-10L80.9,30.8V27.1z"
                />
                <path
                  d="M134,46.5h24.8v7.7h-32.6V0.9h32.5v7.7H134v15h24.2v7.8H134V46.5z"
                />
                <path
                  d="M207.6,38.5h-19.1c-2.1,4.9-4.7,11.3-6.4,15.7h-8.3l21.4-54h5.6l21.4,54H214L207.6,38.5z M191.3,31.2h13.3L198,12.4
                    L191.3,31.2z"
                />
                <path
                  d="M245.4,54.4V8.6h-16.2V0.8h39.9v7.8h-16.1v45.8H245.4z"
                />
                <path
                  d="M295.4,46.5h24.8v7.7h-32.6V0.9h32.5v7.7h-24.7v15h24.2v7.8h-24.2V46.5z"
                />
                <path d="M394.3,54.4V8.6h-16.2V0.8h39.9v7.8H402v45.8H394.3z" />
                <path
                  d="M437,38.2V0.9h7.8v37.4c0,5.9,6.3,8.8,12,8.8c5.6,0,11.9-3,11.9-8.8V0.9h7.8v37.4c0,10.8-10.5,16.5-19.7,16.5
                    C447.4,54.7,437,49.1,437,38.2z"
                />
                <path
                  d="M497,54.2V0.7l5.9,0.1l26,37.6V0.8h7.6v53.4h-6.1l-25.8-37.3v37.3H497z"
                />
                <path
                  d="M564.5,46.5h24.8v7.7h-32.6V0.9h32.5v7.7h-24.7v15h24.2v7.8h-24.2V46.5z"
                />
                <path
                  d="M669.2,0.9c17.5,0,26.2,13.3,26.2,26.7s-8.8,26.6-26.2,26.6h-19.1V0.9H669.2z M669.2,46.5c12.3,0,18.6-9.5,18.6-18.9
                    s-6.3-19-18.6-19h-11.4v37.9H669.2z"
                />
                <path
                  d="M725.4,27.1h9c6.5,0,9.7-4.7,9.7-9.3c0-4.6-3.2-9.2-9.7-9.2h-11.8v45.6H715V0.8h19.4c11.6,0,17.4,8.5,17.4,17
                    c0,7.5-4.7,14.7-13.8,16.3l16.8,20h-10l-19.4-23.4V27.1z"
                />
                <path
                  d="M776.9,8.2c4.9-5,11.7-8.1,19.2-8.1c7.5,0,14.3,3.1,19.3,8.1c4.8,5,7.9,11.7,7.9,19.3c0,7.5-3.1,14.3-7.9,19.3
                    c-5,5-11.7,8.1-19.3,8.1c-7.5,0-14.3-3-19.2-8.1c-4.8-5-7.9-11.7-7.9-19.3C768.9,20,772.1,13.2,776.9,8.2z M809.9,13.7
                    c-3.6-3.6-8.5-5.8-13.8-5.8c-5.3,0-10.1,2.2-13.6,5.8c-3.5,3.5-5.7,8.4-5.7,13.8c0,5.4,2.2,10.3,5.7,13.8c3.5,3.6,8.4,5.8,13.6,5.8
                    c5.3,0,10.2-2.2,13.8-5.8c3.4-3.5,5.6-8.3,5.6-13.8C815.5,22,813.3,17.2,809.9,13.7z"
                />
                <path
                  d="M862.7,0.8c11.5,0,17.3,8.9,17.3,17.8c0,8.9-5.8,17.8-17.3,17.8H851v17.8h-7.8V0.8H862.7z M862.7,28.8
                    c6.5,0,9.7-5,9.7-10.1c0-5.1-3.2-10.1-9.7-10.1H851v20.2H862.7z"
                />
              </g>
            </svg> -->
          </div>

          <div
            class="w-40 flex justify-between md:justify-center md:justify-between"
          >
            <a href="#" target="_blank">
              <svg
                width="32"
                height="32"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 0C7.85054 0 0 7.85054 0 17.5C0 27.1488 7.85054 35 17.5 35C27.1488 35 35 27.1488 35 17.5C35 7.85054 27.1502 0 17.5 0ZM21.8521 18.1161H19.005V28.2639H14.7861C14.7861 28.2639 14.7861 22.7191 14.7861 18.1161H12.7807V14.5295H14.7861V12.2097C14.7861 10.5482 15.5756 7.95205 19.0437 7.95205L22.17 7.96403V11.4456C22.17 11.4456 20.2703 11.4456 19.9009 11.4456C19.5315 11.4456 19.0064 11.6302 19.0064 12.4226V14.5302H22.2208L21.8521 18.1161Z"
                  fill="#064994"
                />
              </svg>
            </a>
            <a href="#" target="_blank">
              <svg
                width="32"
                height="32"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 0C7.85054 0 0 7.85054 0 17.5C0 27.1488 7.85054 35 17.5 35C27.1488 35 35 27.1488 35 17.5C35 7.85054 27.1502 0 17.5 0ZM25.3068 13.4947C25.3146 13.6681 25.3188 13.8429 25.3188 14.0178C25.3188 19.3405 21.2684 25.476 13.8577 25.476C11.583 25.476 9.46548 24.8113 7.68348 23.6679C7.99857 23.7053 8.3193 23.7243 8.64426 23.7243C10.532 23.7243 12.2682 23.08 13.647 22.0001C11.8847 21.9677 10.3967 20.8032 9.88419 19.2023C10.1295 19.2489 10.3826 19.275 10.6413 19.275C11.0085 19.275 11.3652 19.227 11.7028 19.1347C9.86023 18.7653 8.47227 17.1377 8.47227 15.1851C8.47227 15.1682 8.47227 15.1505 8.47297 15.1343C9.01575 15.4353 9.63677 15.6172 10.2966 15.6376C9.21665 14.9165 8.5054 13.6829 8.5054 12.2858C8.5054 11.5471 8.70347 10.8548 9.05029 10.2599C11.036 12.6975 14.0051 14.3004 17.352 14.4696C17.2829 14.1743 17.2483 13.8676 17.2483 13.5511C17.2483 11.3271 19.0515 9.52328 21.2755 9.52328C22.4343 9.52328 23.4797 10.0125 24.2156 10.7949C25.1341 10.6145 25.9941 10.2803 26.7744 9.81723C26.4713 10.7583 25.8348 11.5471 25.0009 12.0461C25.8165 11.9489 26.5947 11.7332 27.3144 11.4124C26.7773 12.2188 26.0942 12.9287 25.3068 13.4947Z"
                  fill="#064994"
                />
              </svg>
            </a>
            <a href="#" target="_blank">
              <svg
                width="32"
                height="32"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.499 21.0034C19.4298 21.0034 21.0038 19.4321 21.0038 17.5C21.0038 16.7373 20.7543 16.0331 20.3412 15.4579C19.7047 14.5753 18.6699 13.9966 17.5012 13.9966C16.3317 13.9966 15.2976 14.5746 14.6597 15.4571C14.2452 16.0324 13.9978 16.7366 13.9971 17.4993C13.995 19.4314 15.5676 21.0034 17.499 21.0034Z"
                  fill="#064994"
                />
                <path
                  d="M25.1498 13.214V10.2774V9.84033L24.7106 9.84174L21.7754 9.85091L21.7867 13.2253L25.1498 13.214Z"
                  fill="#064994"
                />
                <path
                  d="M17.5 0C7.85054 0 0 7.85054 0 17.5C0 27.1488 7.85054 35 17.5 35C27.1488 35 35 27.1488 35 17.5C35 7.85054 27.1502 0 17.5 0ZM27.4526 15.4579V23.6066C27.4526 25.7291 25.727 27.454 23.6059 27.454H11.3941C9.27233 27.454 7.54743 25.7291 7.54743 23.6066V15.4579V11.3948C7.54743 9.27304 9.27233 7.54813 11.3941 7.54813H23.6052C25.727 7.54813 27.4526 9.27304 27.4526 11.3948V15.4579Z"
                  fill="#064994"
                />
                <path
                  d="M22.9444 17.4999C22.9444 20.5007 20.5026 22.9439 17.5004 22.9439C14.4982 22.9439 12.0571 20.5007 12.0571 17.4999C12.0571 16.7781 12.2009 16.0879 12.4575 15.4578H9.48633V23.6065C9.48633 24.6596 10.3414 25.5125 11.3938 25.5125H23.6049C24.6559 25.5125 25.5124 24.6596 25.5124 23.6065V15.4578H22.5398C22.7985 16.0879 22.9444 16.7781 22.9444 17.4999Z"
                  fill="#064994"
                />
              </svg>
            </a>
            <a href="#" target="_blank">
              <svg
                width="32"
                height="32"
                viewBox="0 0 1000 1000"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 500 0C 224 0 0 224 0 500C 0 776 224 1000 500 1000C 776 1000 1000 776 1000 500C 1000 224 776 0 500 0C 500 0 500 0 500 0 M 386 203C 387 203 388 203 388 203C 388 203 395 212 395 212C 267 248 209 304 209 304C 209 304 224 296 250 284C 326 250 386 241 411 239C 415 238 419 238 423 238C 466 232 515 231 566 236C 633 244 705 264 779 304C 779 304 723 251 603 214C 603 214 612 203 612 203C 612 203 709 201 811 277C 811 277 913 462 913 689C 913 689 853 792 697 797C 697 797 671 767 650 740C 743 714 778 656 778 656C 749 675 721 688 697 697C 661 712 627 722 594 728C 526 740 464 737 411 727C 371 719 336 708 307 697C 291 690 273 682 255 673C 253 671 251 670 249 669C 248 668 247 668 246 667C 233 660 226 655 226 655C 226 655 260 711 350 738C 329 765 303 797 303 797C 146 792 87 689 87 689C 87 462 189 277 189 277C 284 206 375 203 386 203C 386 203 386 203 386 203M 368 467C 327 467 296 502 296 545C 296 588 328 624 368 624C 408 624 440 588 440 545C 441 502 408 467 368 467C 368 467 368 467 368 467M 626 467C 586 467 554 502 554 545C 554 588 586 624 626 624C 666 624 698 588 698 545C 698 502 666 467 626 467C 626 467 626 467 626 467"
                  fill="#064994"
                />
              </svg>
            </a>
          </div>
        </div>
        <div class="md:grid grid-cols-2 col-span-2 md:mx-5 font-semibold">
          <nav class="flex flex-col mt-5 md:mt-0 space-y-4 list-none">
            <li>
              <router-link
                to="/market"
                class="button parula__text-black text-xl"
                >Market</router-link
              >
            </li>
            <li>
              <router-link
                to="/activity"
                class="button parula__text-black text-xl font-bold"
                >Activity</router-link
              >
            </li>
            <li v-if="is_logged_in === true">
              <router-link
                to="/mypage"
                class="button parula__text-black text-xl font-bold"
                >My Page</router-link
              >
            </li>
          </nav>

          <nav class="flex flex-col mt-5 md:mt-0 space-y-4 list-none">
            <!-- <li>
              <router-link
                to="/company"
                class="button cursor-pointer parula__text-black cursor-default text-lg font-bold"
                >Company</router-link
              >
            </li> -->
            <li>
              <router-link
                to="/about"
                class="button cursor-pointer parula__text-black cursor-default text-lg font-bold"
                >About Us</router-link
              >
            </li>
            <li>
              <a class="parula__text-black cursor-default text-lg font-bold"
                >Community Guidelines</a
              >
              <!-- <router-link
                to="/mypage"
                class="parula__text-black cursor-default text-lg font-bold"
                >Community Guidelines</router-link
              > -->
            </li>
            <li>
              <a class="parula__text-black cursor-default text-lg font-bold"
                >Help</a
              >
            </li>
          </nav>
        </div>
      </div>
    </section>

    <section class="my-5 md:my-10">
      <hr class="footer__border" />
      <div class="flex flex-wrap flex-col md:flex-row">
        <div class="flex flex-col">
          <p class="text-center md:text-left parula__text-gray mt-5">
            © Copyright all rights reserved.
          </p>
          <a href="https://www.keypair.co.kr" target="_blank" >
            <p class="text-center md:text-right parula__text-gray">
              Powered by Keypair
            </p>
          </a>
        </div>
        <span
          class="
            flex flex-wrap
            justify-center
            md:ml-auto md:justify-start
            parula__text-gray
            mt-5
          "
        >
          <router-link to="/privacy-policy" class="button"
            >Privacy Policy</router-link
          >
          <router-link to="/terms-of-service" class="button ml-5"
            >Terms of Service</router-link
          >
        </span>
      </div>
    </section>
  </footer>
</template>

<script>
import SessionService from "@/services/session.service";

export default {
  data: function() {
    return {
      is_logged_in: false,
    };
  },

  mounted: function() {
    const session = SessionService.loadSession();

    Object.keys(session).length < 1
      ? (this.is_logged_in = false)
      : (this.is_logged_in = true);
  },
};
</script>

<style scoped>
.layout__footer {
  margin: 0px 10%;
}

.footer__border {
  position: absolute;
  left: 0;
  width: 100%;
  border-color: #dddddd;
}

/* @media screen and (max-width: 1940px) {
  .layout__footer {
    margin: 0px 4%;
  }
} */
</style>
