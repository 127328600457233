import { createRouter, createWebHistory } from "vue-router";

const routes = [
  // default layout
  {
    path: "/",
    name: "IndexPage",
    component: () =>
      import(/* webpackChunkName "IndexPage" */ "@/views/index/IndexPage.vue"),
    meta: {
      layout: "ParulaLayoutDefault",
    },
  },

  {
    path: "/authority",
    name: "AuthorityPage",
    component: () =>
      import(
        /* webpackChunkName "AuthorityPage" */ "@/views/authority/AuthorityPage.vue"
      ),
    meta: {
      layout: "ParulaLayoutCompact",
    },
  },

  {
    path: "/welcome/:nickname",
    name: "WelcomePage",
    component: () =>
      import(
        /* webpackChunkName "WelcomePage" */ "@/views/authority/sub/WelcomePage.vue"
      ),
    meta: {
      layout: "ParulaLayoutDefault",
    },
  },

  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName "NotFound" */ "@/views/not-found/NotFound.vue"
      ),
    meta: {
      layout: "ParulaLayoutDefault",
    },
  },

  // adjustment layout
  {
    path: "/market",
    name: "MarketPage",
    component: () =>
      import(
        /* webpackChunkName "MarketPage" */ "@/views/market/MarketPage.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/market/order/:id(\\d+)",
    name: "OrderDetailPage",
    component: () =>
      import(
        /* webpackChunkName "OrderDetailPage" */ "@/views/order-detail/OrderDetailPage.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/product/:id(\\d+)",
    name: "ProductDetailPage",
    component: () =>
      import(
        /* webpackChunkName "ProductDetailPage" */ "@/views/product-detail/ProductDetailPage.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/activity",
    name: "ActivityPage",
    component: () =>
      import(
        /* webpackChunkName "ActivityPage" */ "@/views/activity/ActivityPage.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/mypage",
    name: "MyPage",
    component: () =>
      import(/* webpackChunkName "MyPage" */ "@/views/my-page/MyPage.vue"),
    meta: {
      layout: "ParulaLayoutDefault",
    },
  },

  {
    path: "/mypage/product/mint",
    name: "ProductRegister",
    component: () =>
      import(
        /* webpackChunkName "ProductRegister" */ "@/views/my-page/ProductRegister.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/mypage/order/new",
    name: "OrderRegister",
    component: () =>
      import(
        /* webpackChunkName "OrderRegister" */ "@/views/my-page/OrderRegister.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/email/confirm/:id/:secret",
    name: "EmailConfirmPage",
    component: () =>
      import(
        /* webpackChunkName "EmailConfirm" */ "@/views/email-validate/EmailConfirm.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/test",
    name: "Test",
    component: () => import(/* webpackChunkName "Test" */ "@/views/Test.vue"),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName "PrivacyPolicy" */ "@/views/links/PrivacyPolicy.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: () =>
      import(
        /* webpackChunkName "TermsOfService" */ "@/views/links/TermsOfService.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/company",
    name: "CompanyPage",
    component: () =>
      import(
        /* webpackChunkName "CompanyPage" */ "@/views/links/CompanyPage.vue"
      ),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/about",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName "AboutUs" */ "@/views/links/AboutUs.vue"),
    meta: {
      layout: "ParulaLayoutAdjustment",
    },
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
