<template>
  <div
    class="
      subscription__layout
      flex flex-col
      items-center
      w-full
      px-4
      text-center
      parula__bg-green
    "
  >
    <div>
      <h1 class="text-white md:text-2xl">Be an Artist on CILO</h1>
      <p class="mt-4 text-white text-xs md:text-base">
        Join our community in our Artist’s Enclave. Showcase, promote and sell
        in our digital marketplace.
      </p>
    </div>
    <form
      action="#"
      class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mt-12"
      @submit.prevent="sendContact"
    >
      <div class="relative">
        <input
          type="email"
          class="
            w-full
            px-2
            py-3
            bg-white
            shadow-inner
            rounded-none
            focus:outline-none
            text-xs
            md:text-base
          "
          ref="email"
          placeholder="Email address"
          required
        />
        <button
          class="
            absolute
            px-2
            md:px-4
            py-2
            rounded-none
            right-1
            top-1
            text-xs
            md:text-base
            text-white
            parula__bg-green
          "
        >
          Send
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import OpenapiService from "../../services/openapi.service";

export default {
  props: ["image", "title", "pen_name"],

  methods: {
    sendContact() {
      const email = this.$refs.email.value;
      OpenapiService.contact(email, "become-an-artist")
        .then(() => {
          this.$notify({
            type: "success",
            title: "success",
            text: "Contact requested",
          });
        })
        .catch((err) => {
          let msg = err.response ? err.response.data : "unknown error";
          this.$notify({ type: "error", title: "fail", text: msg });
          console.error(msg);
        });
    },
  },
};
</script>

<style scope>
.subscription__layout {
  margin: 4.5rem 0px;
  padding: 4.5rem 0px;
}
</style>
