<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 overflow-hidden z-50"
      @close="open = false"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />
        </TransitionChild>
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="relative w-screen max-w-md">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="
                    absolute
                    top-0
                    left-0
                    -ml-8
                    pt-4
                    pr-2
                    flex
                    sm:-ml-10 sm:pr-4
                  "
                >
                  <button
                    type="button"
                    class="
                      rounded-md
                      text-gray-300
                      hover:text-white
                      focus:outline-none focus:ring-2 focus:ring-white
                    "
                    @click="open = false"
                  >
                    <span class="sr-only">Close panel</span>
                    <div class="h-6 w-6" aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </TransitionChild>
              <div
                class="
                  h-full
                  flex flex-col
                  py-6
                  bg-white
                  shadow-xl
                  overflow-y-scroll
                "
              >
                <div class="flex justify-between px-4 sm:px-6">
                  <DialogTitle class="text-lg font-medium parula__text-black">
                    Wallet Setting
                  </DialogTitle>
                  <button
                    class="text-xs font-semibold self-end cursor-pointer button-clear"
                    @click="init"
                  >
                    Clear All
                  </button>
                </div>
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <div
                    v-if="addData.status == 'none'"
                    class="mt-3 w-full flex flex-col"
                  >
                    <input
                      v-model="addedAccount"
                      class="
                        border
                        parula__border-blue
                        rounded-sm
                        w-full
                        p-2
                        mb-5
                      "
                      placeholder="0x"
                    />
                    <button
                      @click="onAddWallet"
                      class="
                        text-sm
                        font-semibold
                        button-green
                        self-end
                      "
                    >
                      ADD
                    </button>
                  </div>
                  <div
                    v-else-if="addData.status == 'signed'"
                    class="
                      mt-3
                      p-2
                      w-full
                      flex flex-col
                      space-y-5
                    "
                  >
                    <div class="">
                      <label
                        class="block text-sm font-semibold parula__text-black mb-3"
                        >Signer</label
                      >
                      <div
                        class="
                         w-full
                          px-5
                          py-3
                          parula__bg-gray-soft
                          rounded
                        "
                      >
                        <span class="text-sm break-all">{{
                          addData.signer
                        }}</span>
                      </div>
                    </div>
                    <div class="">
                      <label
                        class="block text-sm font-semibold parula__text-black mb-3"
                        >Raw data</label
                      >
                      <div
                        class="
                          w-full
                          px-5
                          py-3
                          parula__bg-gray-soft
                          rounded
                        "
                      >
                        <span class="text-sm break-all">{{
                          addData.raw_data
                        }}</span>
                      </div>
                    </div>
                    <div class="">
                      <label
                        class="block text-sm font-semibold parula__text-black mb-3"
                        >Signed data</label
                      >
                      <div
                        class="
                          w-full
                          px-5
                          py-3
                          parula__bg-gray-soft
                          rounded
                        "
                      >
                        <p class="text-sm break-all">{{ addData.signed }}</p>
                      </div>
                    </div>
                    <div class="flex justify-end">
                      <button
                        @click="
                          insertAccount(verifyData.msgParams, verifyData.sign)
                        "
                        class="
                          text-sm
                          font-semibold
                          button-green
                        "
                      >
                        Confirm
                      </button>
                    </div>
                  </div>

                  <div
                    v-else-if="addData.status === 'added'"
                    class="
                      mt-3
                      p-2
                      w-full
                      flex flex-col
                      space-y-5
                    "
                  >
                    <div>
                      <label
                        class="block text-sm font-semibold parula__text-black mb-3"
                        >Added Account</label
                      >
                      <div
                        class="
                          w-full
                          px-5
                          py-3
                          parula__bg-gray-soft
                          rounded
                        "
                      >
                        <span class="text-sm break-all">{{
                          addedAccount
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        class="block text-sm font-semibold parula__text-black mb-3"
                        >Login Account</label
                      >
                      <div
                        class="
                          w-full
                          px-5
                          py-3
                          parula__bg-gray-soft
                          rounded
                        "
                      >
                        <span class="text-sm break-all">{{
                          loginAccount
                        }}</span>
                      </div>
                    </div>

                    <div class="flex justify-end">
                      <button
                        @click="onGetAuth"
                        class="
                          text-sm
                          font-semibold
                          button-green
                        "
                      >
                        Get Auth
                      </button>
                    </div>
                  </div>

                  <div
                    v-else-if="addData.status == 'loading'"
                    class="
                      mt-3
                      p-2
                      w-full
                      flex flex-col
                      bg-white
                      shadow-lg
                      space-y-3
                    "
                  >
                    <div
                      class="h-2 w-10 bg-parula-gray-2 animate-pulse rounded-lg"
                    ></div>
                    <div
                      class="
                        w-full
                        h-8
                        py-1
                        animate-pulse
                        bg-parula-gray-2
                        rounded
                      "
                    ></div>
                    <div
                      class="h-2 w-10 bg-parula-gray-2 animate-pulse rounded-lg"
                    ></div>
                    <div
                      class="
                        w-full
                        h-8
                        py-1
                        animate-pulse
                        bg-parula-gray-2
                        rounded
                      "
                    ></div>
                    <div
                      class="h-2 w-10 bg-parula-gray-2 animate-pulse rounded-lg"
                    ></div>
                    <div
                      class="
                        w-full
                        h-8
                        py-1
                        animate-pulse
                        bg-parula-gray-2
                        rounded
                      "
                    ></div>

                    <div class="flex justify-end">
                      <div
                        class="
                          w-20
                          h-5
                          rounded-xl
                          bg-parula-gray-2
                          animate-pulse
                        "
                      ></div>
                    </div>
                  </div>
                  <!-- block text-sm   mb-3 -->
                  <h1 class="my-5 parula__text-black text-sm font-semibold">
                    Wallet list
                  </h1>
                  <div class="w-full flex justify-center">
                    <div class="rounded-sm w-full">
                      <ul class="divide-y">
                        <li
                          v-for="i in ds"
                          :key="i.account"
                          @click="onLoginWalletChange(i.account)"
                          class="p-4 hover:bg-gray-50 cursor-pointer"
                        >
                          <div class="flex justify-between items-center">
                            <div class="text-xs">
                              {{ i.account }}
                            </div>
                            <div
                              v-if="loginAccount === i.account"
                              class="text-xs parula__text-blue"
                            >
                              login
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- modal -->
              <div>
                <LoadingModal ref="LoadingModal" />
                <ConfirmModal
                  ref="AddConfirmModal"
                  :msg="addConfirmMessage"
                  :modalFunction="verifyWallet"
                />
                <ConfirmModal
                  ref="AuthConfirmModal"
                  :msg="AuthConfirmMessage"
                  :modalFunction="getWalletAuth"
                />
                <ResultModal
                  ref="ResultModal"
                  :msg="resultMessage"
                  :modalFunction="init"
                />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import Web3 from "web3";
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import SessionService from "../../../services/session.service";
import OpenapiAuth from "../../../services/openapiAuth.service";
import { notify } from "@kyvg/vue3-notification";
import LoadingModal from "@/components/modal/LoadingModal.vue";
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import ResultModal from "@/components/modal/ResultModal.vue";

export default {
  props: {
    userData: Object,
  },

  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LoadingModal,
    ConfirmModal,
    ResultModal,
  },

  setup() {
    const open = ref(false);
    return {
      open,
    };
  },

  data: function() {
    return {
      ds: [],
      addedAccount: "",
      addConfirmMessage: "",
      AuthConfirmMessage: "",
      resultMessage: "",
      loginAccount: "",

      verifyData: {
        msgParams: "",
        sign: "",
      },

      addData: {
        status: "none",
        signer: "0x",
        signed: "signed",
        raw_data: "raw_data",
      },
    };
  },

  methods: {
    show() {
      this.open = true;
      this.onLoad();
    },

    async onLoad() {
      this.loginAccount = await SessionService.getWallet();

      OpenapiAuth.walletList(this.userData.id)
        .then((res) => {
          this.ds = res.data.items;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

    // *********************************************

    // async onSelectAccount() {
    //   this.$refs.LoadingModal.open();

    //   await this.selectWallet();

    //   if (!this.addedAccount) return;

    //   this.$refs.LoadingModal.close();
    // },

    async onAddWallet() {
      this.$refs.LoadingModal.open();

      if (this.addedAccount.length !== 42) {
        notify({
          type: "error",
          text: "Invalid wallet.",
        });
        this.init();
        return;
      }

      const duplication = await this.walletDoubleCheck(this.addedAccount);
      if (duplication) {
        notify({
          type: "error",
          text: `${this.addedAccount} is already registered.`,
        });
        this.init();
        return;
      }

      this.addConfirmMessage = `PARULA verify that added account (${this.addedAccount}) is yours`;

      // AddConfirmModal에서 verifyWallet() 실행
      this.$refs.LoadingModal.close();
      this.$refs.AddConfirmModal.open();
    },

    async onGetAuth() {
      this.AuthConfirmMessage = `PARULA needs login account's (${this.loginAccount}) auth`;
      this.$refs.AuthConfirmModal.open();
    },

    // async onVerifyWallet() {
    //   await this.verifyWallet();
    // },

    // =============================================

    // async selectWallet() {
    //   const permissions = await window.ethereum.request({
    //     method: "wallet_requestPermissions",
    //     params: [{ eth_accounts: {} }],
    //   });

    //   if (permissions[0].caveats[0].value.length !== 1) {
    //     this.addedAccount = "";
    //     notify({
    //       type: "error",
    //       text: "Permission wallet must be one.",
    //     });
    //     return;
    //   }

    //   const accountsPermission = await permissions.find(
    //     (permission) => permission.parentCapability === "eth_accounts"
    //   );

    //   if (!accountsPermission) {
    //     this.addedAccount = "";
    //     console.error("eth_accounts permission is not requested!");
    //     return;
    //   }

    //   this.addedAccount = accountsPermission.caveats[0].value[0];
    // },

    walletDoubleCheck(account) {
      return new Promise((resolve) => {
        OpenapiAuth.walletDoubleCheck(account).then((result) => {
          const { length } = result.data;

          resolve(length === 0 ? false : true);
        });
      }).catch((error) => {
        console.error(error);
      });
    },

    // =============================================\

    async getWalletAuth() {
      const loginAccount = this.loginAccount;

      await window.ethereum
        .request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: { loginAccount } }],
        })
        .then((permissions) => {
          const accountsPermission = permissions.find(
            (permission) => permission.parentCapability === "eth_accounts"
          );
          if (!accountsPermission) {
            this.init();
            console.error("eth_accounts permission is not requested!");
            return;
          }
        })
        .catch((error) => {
          if (error.code === 4001) {
            console.log("Permissions needed to continue.");
          } else {
            console.error(error);
          }
        });

      const web3 = new Web3(window.web3.currentProvider);
      const from = await web3.eth.getAccounts();
      const account = from[0];

      // 로그인 계정과 account가 다르면 error
      if (account.toUpperCase() !== loginAccount.toUpperCase()) {
        notify({
          type: "error",
          text: `Auth account should be login wallet.`,
        });
        this.init();
        return;
      }

      let expireDate = new Date();
      // expire date add 1day
      expireDate.setDate(expireDate.getDate() + 1);

      const msgParams = `add_wallet ${account} ${Date.parse(expireDate)}`;

      let strMethod = "personal_sign";
      let paramValue = [msgParams, account];

      web3.currentProvider
        .send(strMethod, paramValue)
        .then((res) => {
          this.addData.signer = account;
          this.addData.raw_data = msgParams;
          this.addData.signed = res.result;
          this.addData.status = "signed";
          this.$refs.AuthConfirmModal.close();
        })
        .catch((error) => {
          this.init();
          notify({
            type: "error",
            text: `${error.message}`,
          });
        });
    },

    async verifyWallet() {
      const addedAccount = this.addedAccount;

      await window.ethereum
        .request({
          method: "wallet_requestPermissions",
          params: [{ eth_accounts: { addedAccount } }],
        })
        .then((permissions) => {
          const accountsPermission = permissions.find(
            (permission) => permission.parentCapability === "eth_accounts"
          );
          if (!accountsPermission) {
            this.init();
            console.error("eth_accounts permission is not requested!");
            return;
          }
        })
        .catch((error) => {
          if (error.code === 4001) {
            console.log("Permissions needed to continue.");
          } else {
            console.error(error);
          }
        });

      const web3 = new Web3(window.web3.currentProvider);
      const from = await web3.eth.getAccounts();
      const account = from[0];

      if (account.toUpperCase() !== this.addedAccount.toUpperCase()) {
        notify({
          type: "error",
          text: `Verified account should be added wallet.`,
        });
        this.init();
        return;
      }

      const expireDate = new Date();
      // expire date add 1day
      expireDate.setDate(expireDate.getDate() + 1);
      const msgParams = `verify_wallet ${account} ${Date.parse(expireDate)}`;

      const strMethod = "personal_sign";
      const paramValue = [msgParams, account];

      web3.currentProvider
        .send(strMethod, paramValue)
        .then((res) => {
          this.verifyData.msgParams = msgParams;
          this.verifyData.sign = res.result;
          this.addData.status = "added";
          this.$refs.AddConfirmModal.close();
        })
        .catch((error) => {
          this.init();
          notify({
            type: "error",
            text: `${error.message}`,
          });
        });
    },

    insertAccount(msgParams, signedMessage) {
      this.$refs.LoadingModal.open();
      const userId = SessionService.getUserId();

      OpenapiAuth.insertAccount(
        "ETH", //   platform
        this.addedAccount, //   account
        userId, //   user_id:
        null,
        null,
        this.addData.signer,
        this.addData.raw_data,
        this.addData.signed,
        msgParams,
        signedMessage
      )
        .then(() => {
          this.$refs.LoadingModal.close();
          this.resultMessage = `${this.addedAccount} is successfully added`;
          this.$refs.ResultModal.open();
        })
        .catch((error) => {
          this.$refs.LoadingModal.close();
          this.resultMessage = "ERROR has occur";
          this.$refs.ResultModal.open();
          console.error(error);
        });
    },

    init() {
      this.$refs.LoadingModal.close();
      this.$refs.AuthConfirmModal.close();
      this.$refs.AddConfirmModal.close();
      this.$refs.ResultModal.close();

      this.addedAccount = "";
      this.addConfirmMessage = "";
      this.AuthConfirmMessage = "";
      this.resultMessage = "";
      this.loginAccount = "";

      this.verifyData = {
        msgParams: "",
        sign: "",
      };

      this.addData = {
        status: "none",
        signer: "0x",
        signed: "signed",
        raw_data: "raw_data",
      };

      this.onLoad();
    },

    // =============================================

    async onLoginWalletChange(account) {
      const permissions = await window.ethereum.request({
        method: "wallet_requestPermissions",
        params: [{ eth_accounts: { account } }],
      });

      if (permissions[0].caveats[0].value.length !== 1) {
        notify({
          type: "error",
          text: "Permission wallet must be one.",
        });
        console.error("Permission wallet must be one.");
        return;
      }

      const accountsPermission = await permissions.find(
        (permission) => permission.parentCapability === "eth_accounts"
      );

      if (!accountsPermission) {
        console.error("eth_accounts permission has not requested!");
        return;
      }

      const signedAccount = accountsPermission.caveats[0].value[0];

      if (account.toUpperCase() !== signedAccount.toUpperCase()) {
        notify({
          type: "error",
          text: "Signed account is not selected account.",
        });
        return;
      }

      try {
        await SessionService.setAccount(account);
      } catch (error) {
        notify({
          type: "error",
          text: `${error.message}`,
        });
      } finally {
        this.onLoad();
        this.$router.go();
      }
    },

    // onClipboard(_addr) {
    //   var tempElem = document.createElement("textarea");
    //   tempElem.value = _addr;
    //   document.body.appendChild(tempElem);

    //   tempElem.select();
    //   document.execCommand("copy");
    //   document.body.removeChild(tempElem);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";
@import "@/scss/_text.scss";
@import "@/scss/_scrollbar.scss";

.button-clear {
  @include button-radius(5rem, 2rem, $gray, $white);
}

.button-green {
  @include button-radius(8rem, 2.5rem, #20A97F, $white);
}
</style>
