<template>
  <div
    class="layout__modal fixed inset-0 z-50"
    :class="{ modal__visible: visible, modal__invisible: !visible }"
  >
    <div
      class="fixed inset-0 bg-black bg-opacity-70 transition-opacity parula__text-white"
    >
      <div class="layout__modal__container overflow-y-scroll">
        <div class="w-12 h-12 lg:w-16 lg:h-16">
          <img src="@/assets/img/cilo_loading.gif" alt="loading" />
        </div>

        <div class="my-4 px-12 overflow-y-auto">
          {{ msg }}
        </div>

        <div class="flex justify-between gap-x-2">
          <button @click="close" class="button-radius-blue">
            CANCEL
          </button>
          <button @click="modalFunction" class="button-blue">
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      required: true,
    },

    modalFunction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  methods: {
    open() {
      this.visible = true;
    },

    close() {
      this.visible = false;
    },

    executeModalFunction() {
      // this.modalFunction();
      // console.log(this.$refs);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/_text.scss";
@import "@/scss/_button.scss";

.button-radius-blue {
  font-size: small;
  font-weight: 600;
  @include button-white-radius(8rem, 2rem, $blue, $blue);
}

.button-blue {
  font-size: small;
  font-weight: 600;
  @include button-radius(8rem, 2rem, $blue);
}

.modal__visible {
  display: block;
}
.modal__invisible {
  display: none;
}

.layout__modal {
  min-height: 100vh;
  .layout__modal__container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @extend %text__align;
  }
}

@include less-than-md {
  .layout__modal__container {
    width: 80vw;
    padding: 1rem 0px;
  }
}

@include more-than-md {
  .layout__modal__container {
    width: 60vw;
    padding: 2rem 0px;
  }
}
</style>
