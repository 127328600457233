<template>
  <header class="bg-white shadow-sm">
    <div class="relative layout__header flex justify-between items-center">
      <!-- logo -->
      <div class="flex justify-center items-center" @click="clicked = null">
        <router-link to="/" class="flex button">
          <img
            class="img__max-size w-60 sm:w-96"
            src="@/assets/img/ciloxnbc.png"
          />
          <!-- <svg
            id="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 226.77 226.77"
            class="w-7 md:w-10"
          >
            <path
              fill="#fff"
              d="M10.19,114.09c.74,51.6,42.8,93.2,94.58,93.2s93.84-41.6,94.58-93.2H10.19Z"
            />
            <path
              fill="#0058a7"
              d="M104.63,19.22c-40.27,0-74.62,25.28-88.09,60.82,2.77-.25,5.58-.38,8.41-.38,32.88,0,61.82,16.86,78.66,42.39l1.25,.91,87.44-44.01c-13.75-34.98-47.82-59.74-87.67-59.74Z"
            />
            <path
              fill="#ffd300"
              d="M24.96,79.66c-2.84,0-5.64,.13-8.41,.38-3.93,10.37-6.08,21.6-6.08,33.35,0,52.01,42.16,94.17,94.17,94.17,2.84,0,5.64-.13,8.41-.38,3.93-10.37,6.08-21.6,6.08-33.35,0-52.01-42.16-94.17-94.17-94.17Z"
            />
            <polygon
              fill="#231815"
              points="20.35 70.77 .29 76 12.5 90.82 67.43 83.85 20.35 70.77"
            />
            <circle
              fill="#fff"
              cx="43.64"
              cy="81.57"
              r="13.7"
              transform="translate(-38.34 124.44) rotate(-89.46)"
            />
            <circle
              fill="#231815"
              cx="43.54"
              cy="81.56"
              r="9.59"
              transform="translate(-43.86 111.7) rotate(-80.93)"
            />
            <path
              fill="#7b838b"
              d="M196.44,90.23l-93.05,30.21c11.32,34.09,47.34,53.14,81.68,43.7,9.14-14.49,14.43-31.66,14.43-50.06,0-8.24-1.06-16.24-3.06-23.86Z"
            />
            <path
              fill="#7b838b"
              d="M219.15,60.71l-115.73,60.88c12.85,24.04,38.62,36.82,64.21,34.25-5.88-14.58-16.34-26.88-29.62-35.14,16.59,5.79,31.04,17.33,40.11,33.21,1.23-.33,2.46-.7,3.68-1.11-6.81-13.8-17.83-25.11-31.32-32.4,16.08,4.54,30.55,14.38,40.56,28.49,.16-.08,.32-.16,.48-.25,31.83-16.74,44.14-56.03,27.63-87.92Z"
            />
            <path
              fill="#fff"
              d="M137.76,120.41c13.34,8.3,23.84,20.64,29.75,35.29,3.52-.35,7.04-1,10.53-1.94-9.1-15.95-23.61-27.53-40.27-33.35Z"
            />
            <path
              fill="#fff"
              d="M150.29,120.1c13.54,7.32,24.6,18.68,31.44,32.54,3.14-1.05,6.25-2.36,9.29-3.93-10.05-14.17-24.58-24.05-40.73-28.61Z"
            />
          </svg> -->

          <!-- <svg
            id="logo__text"
            data-name="logo__text"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 878.74 164.41"
            class="w-0 hidden invisible md:w-24 md:ml-3 md:visible"
          >
            <path
              d="M57.92,3.22c34.15,0,51.43,26.46,51.43,53.02s-17.18,52.72-51.43,52.72H23.07v52.72H0V3.22H57.92Zm0,83.08c19.27,0,28.76-14.98,28.76-29.86s-9.49-30.16-28.76-30.16H23.07v60.01H57.92Z"
            />
            <path
              d="M219.69,115.06h-56.82c-6.09,14.48-14.08,33.55-18.97,46.63h-24.66L182.74,1.12h16.78l63.61,160.57h-24.46l-18.97-46.63Zm-48.23-21.57h39.34l-19.47-55.92-19.87,55.92Z"
            />
            <path
              d="M328.13,81.31h26.76c19.27,0,28.76-13.78,28.76-27.66s-9.49-27.36-28.76-27.36h-35.05V161.69h-22.67V3.22h57.72c34.45,0,51.63,25.36,51.63,50.53,0,22.37-13.78,43.74-40.94,48.43l50.03,59.51h-29.66l-57.82-69.5v-10.88Z"
            />
            <path
              d="M449.36,114.16V3.42h23.07V114.36c0,17.47,18.77,26.06,35.55,26.06s35.35-8.79,35.35-26.26V3.42h23.07V114.36c0,32.15-31.26,48.93-58.42,48.93s-58.62-16.78-58.62-49.13Z"
            />
            <path d="M639.08,138.82h74.49v22.87h-97.36V3.42h22.87V138.82Z" />
            <path
              d="M835.3,115.06h-56.82c-6.09,14.48-14.08,33.55-18.97,46.63h-24.66L798.36,1.12h16.78l63.61,160.57h-24.46l-18.97-46.63Zm-48.23-21.57h39.34l-19.47-55.92-19.87,55.92Z"
            />
          </svg> -->
        </router-link>
      </div>

      <div class="flex gap-x-2 md:gap-x-10">
        <!-- navbar -->
        <nav class="flex items-center gap-x-2 md:gap-x-10">
          <div class="hover__market cursor-pointer">
            <router-link to="/market" @click="clicked = 'market'">
              <span
                class="hover__border text-xs sm:text-base font-semibold"
                :class="{ clicked__border: clicked === 'market' }"
                >Market</span
              >
            </router-link>

            <ul
              class="market-dropdown nav__position shadow-md"
              ref="MarketDropdown"
            >
              <li
                class="dropdown__item"
                @click="[onGoMarket(), (clicked = 'market')]"
              >
                All NFTs
              </li>
              <li
                class="dropdown__item"
                @click="[onGoMarket('fine'), (clicked = 'market')]"
              >
                Fine Art
              </li>
              <li
                class="dropdown__item"
                @click="[onGoMarket('coll'), (clicked = 'market')]"
              >
                Collaboration
              </li>
            </ul>
          </div>

          <router-link
            to="/activity"
            class="button"
            @click="clicked = 'activity'"
          >
            <span
              class="hover__border text-xs sm:text-base font-semibold"
              :class="{ clicked__border: clicked === 'activity' }"
            >
              Activity
            </span>
          </router-link>
        </nav>

        <!-- user avatar -->
        <section>
          <button
            v-if="loginStatus === 'notInstalled'"
            @click="onSwitchInstallWallet"
            v-click-outside="onClickOutside"
          >
            <span
              class="w-full p-1 mr-2 sm:mr-0 text-xs sm:text-base font-semibold"
            >
              Install Wallet
            </span>

            <!-- install metamask modal -->
            <div v-if="installWallet === true">
              <div class="absolute nav__position right-0">
                <a
                  href="https://metamask.io/download.html"
                  target="_blank"
                  @click="onSwitchInstallWallet"
                >
                  <div class="flex items-center gap-x-2">
                    <img class="h-8 w-8" src="@/assets/img/metamask.png" />
                    <span class="text-sm text-gray-600"> Metamask </span>
                  </div>
                </a>
              </div>
            </div>
          </button>

          <router-link
            v-else-if="loginStatus === 'unauthorized'"
            to="/authority?mode=login"
            @click="clicked = null"
          >
            <span
              class="hover__border-black text-xs sm:text-base font-semibold"
            >
              Connect Wallet
            </span>
          </router-link>

          <div
            v-else-if="loginStatus === 'authorized'"
            class="inline-block text-left dropdown"
          >
            <button
              class="
                inline-flex
                justify-center
                w-full
                sm:px-4
                py-2
                text-sm
                font-medium
                leading-5
                text-gray-700
                transition
                duration-150
                ease-in-out
              "
              type="button"
              aria-haspopup="true"
              aria-expanded="true"
              aria-controls="login-dropdown-ctrl"
              @click="onSwitchUserMenu"
              v-click-outside="onClickOutside"
            >
              <img
                v-if="session.avatar"
                class="
                  object-cover
                  img__max-size
                  w-7
                  h-7
                  md:w-12 md:h-12
                  rounded-full
                  border border-gray-soft
                "
                :src="session.avatar"
                alt="login"
              />
              <img
                v-else
                class="
                  object-cover
                  img__max-size
                  w-7
                  h-7
                  md:w-12 md:h-12
                  rounded-full
                "
                :src="pic"
                alt="login"
              />
            </button>
            <div class="dropdown-menu">
              <transition name="bounce2">
                <WalletSetting :userData="userData" ref="WalletSetting" />
              </transition>
              <div
                v-if="userMenu === true"
                class="
                  absolute
                  right-0
                  w-56
                  bg-white
                  border parula__border-gray-soft rounded-lg
                  divide-y
                  shadow-md
                "
                id="login-dropdown-ctrl"
                role="menu"
              >
                <div class="px-4 py-3">
                  <p class="text-sm leading-5 text-black">Signed in as</p>
                  <p class="text-sm font-medium leading-5 text-black truncate">
                    {{ session.wallet }}
                  </p>
                </div>
                <div>
                  <div>
                    <a
                      href="javascript:;"
                      @click="
                        onMyPage();
                        clicked = null;
                      "
                      tabindex="0"
                      class="
                      text-gray
                      flex
                      justify-between
                      w-full
                      px-4
                      py-2
                      text-sm
                      leading-5
                      text-left
                      hover__bg__green
                    "
                      role="menuitem"
                      >My Page</a
                    >
                  </div>
                  <div>
                    <span
                      href="javascript:;"
                      @click="onAddWallet"
                      tabindex="0"
                      class="
                        text-gray
                        flex
                        justify-between
                        w-full
                        px-4
                        py-2
                        text-sm
                        leading-5
                        text-left
                        hover__bg__green
                        cursor-pointer
                      "
                      role="menuitem"
                      >Wallet Setting</span
                    >
                  </div>
                  <div>
                    <a
                      href="javascript:void(0)"
                      @click="onSignOut"
                      tabindex="3"
                      class="
                        text-gray
                        flex
                        justify-between
                        w-full
                        px-4
                        py-2
                        text-sm
                        leading-5
                        text-left
                        hover__bg__green
                      "
                      role="menuitem"
                      >Sign out</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Network Warning Message -->
    <section v-if="!isMainNet" class="flex items-center">
      <div class="layout__warning-center"></div>
      <div class="layout__warning text-xs md:text-base">
        Your wallet is connected to the test network.
      </div>

      <svg
        @click="isMainNet = true"
        class="layout__warning-center cursor-pointer p-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="m10 4.3-10 10 1.4 1.4 8.6-8.6 8.6 8.6 1.4-1.4z"
          fill="#FFFFFF"
        />
      </svg>
    </section>
  </header>
</template>

<script>
import vClickOutside from "click-outside-vue3";
// import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";
import OpenapiAuth from "@/services/openapiAuth.service";
import SessionService from "@/services/session.service.js";
import pic from "@/assets/img/basic_image.png";

import WalletSetting from "../../views/my-page/sub/WalletSetting.vue";
import openapiService from "../../services/openapi.service";
import { checkMetamaskInstallation } from "@/util/wallet";

//-- For walletconnect
import connect from '../../composables/connect/index';
import {onMounted} from "vue";

//!--

export default {
  data: function() {
    return {
      isMainNet: true,
      installWallet: false,
      userMenu: false,
      addr: "",
      providerAddr: "",
      selectedChainId: 0x00,
      hideSignup: true,
      isLogin: false, //로그인 여부 확인값
      loginStatus: "unauthorized",
      isSigned: false, // 미가입
      metaConnected: false,
      userData: {},
      session: {
        lastTime: null,
        address: null,
        name: null,
        avatar: null,
      },
      pic: pic,
      clicked: null,
    };
  },

  components: {
    WalletSetting,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  // computed: {
  //   web3() {
  //     // let web3 = new Web3(window.web3.currentProvider);
  //     let web3 = new Web3(window.ethereum);
  //     return web3;
  //   },
  // },

  async created() {
    await this.startLogin();
    console.log("###-created", this.session);
    if (this.loginStatus === "notInstalled") {
      this.isMainNet = true;
    } else {
      if (this.session.platform === 'walletconnect') {
        // todo : support wc.

      } else {
        const web3 = new Web3(Web3.givenProvider);

        this.selectedChainId = await web3.eth.net.getId();
        console.log("this.selectedChainId ==> ", this.selectedChainId);
        this.selectedChainId === 1 || this.selectedChainId === 8217
          ? (this.isMainNet = true)
          : (this.isMainNet = false);
      }
    }

    this.emitter.on("CURRENT_AVATAR", (data) => {
      this.session.avatar = data;

      //세션정보 저장
      SessionService.setItems({
        platform: this.session.platform,
        wallet: this.session.wallet,
        nickname: this.session.nickname,
        avatar: this.session.avatar,
        msg: this.session.msg,
        sig: this.session.sig,
      });
    });
  },
  
  setup: () => {
    const {state, autoConnect, disconnectWallet} = connect();
    console.log("setup....");

    onMounted(async () => {
      console.log("onMounted", state.status);
      try{
        if (!state.status) {
          await disconnectWallet();
//        } else {
          console.log("call signout");
          onSignOut();
          //await autoConnect()
        }
      } catch(error)
      {
        console.log("onMounted-error", error);
        
      }
    })
  },

  methods: {
    onGoMarket(category) {
      const url = category ? `/market/?category=${category}` : "/market";
      console.log("url: ", url);
      this.$router.push(url);
    },

    onClickOutside() {
      this.installWallet = false;
      this.userMenu = false;
    },

    onSwitchInstallWallet() {
      this.installWallet = !this.installWallet;
    },

    onSwitchUserMenu() {
      this.userMenu = !this.userMenu;
    },

    onMyPage() {
      this.$router.push("/mypage");
      this.onSwitchUserMenu();
    },

    onSignOut: async function() {
      this.onSwitchUserMenu();

      await SessionService.logout();
      this.$router.push("/").then(() => {
        this.$router.go();
      });
    },

    onAddWallet() {
      this.$refs.WalletSetting.show();
    },

    handleChainChanged: function(_chainId) {
      this.selectedChainId = _chainId;
      console.log("this.selectedChainId  ==> ", this.selectedChainId )

      this.selectedChainId === "0x1" || this.selectedChainId === "0x2019"
        ? (this.isMainNet = true)
        : (this.isMainNet = false);
    },

    handleAccountsChanged: function(accounts) {
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
      } else if (accounts[0] !== this.providerAddr) {
        this.providerAddr = accounts[0];
      }
    },

    updated() {
      console.log("@@@@", "---updated");
    }, 

    async startLogin() {
      const {state} = connect();

      this.session = await SessionService.loadSession();
      
      console.log("@@@", "startLogin", state, this.session);

      if (!state.status) {
        this.loginStatus = "unauthorized";
      }

      if (Object.keys(this.session).length < 1) {
        this.loginStatus = "unauthorized";

        console.log("@@@", "startLogin-loginStatus",  this.loginStatus);        
      } else {
        if (this.session.platform === 'walletconnect') {
          if (!state.status) {
            this.loginStatus = "unauthorized";
          } else {
            const account = this.session.wallet;
            console.log("@@@", "startLogin-walletconnect-accounts", account);        
            
            this.addr = account;
            if (SessionService.valid(this.addr)) {
              await openapiService.userByAddress(this.addr).then((res) => {
                this.userData = res.data.items[0];
              });
              this.loginStatus = "authorized";
              this.saveAddr(this.addr);
            } else {
              this.loginStatus = "unauthorized";
            }
          }
        } else {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          console.log("@@@", "startLogin-metamask-accounts", accounts);        

          this.addr = accounts[0];
          if (SessionService.valid(this.addr)) {
            await openapiService.userByAddress(this.addr).then((res) => {
              this.userData = res.data.items[0];
            });
            this.loginStatus = "authorized";
            this.saveAddr(this.addr);
          } else {
            this.loginStatus = "unauthorized";
          }

          // 체인변경이벤트 등록
          window.ethereum.on("chainChanged", this.handleChainChanged);
          window.ethereum.on("accountsChanged", this.handleAccountsChanged);
        }
      }

      return;
    },

    caseNotRegistered: function() {
      const res = confirm(
        `
          ${this.addr} \n is not registered! Would you like to sign up? \n\n\
          If you have a registered account, please change the provider's wallet
        `
      );
      if (res) {
        this.isLogin = false;
        this.hideSignup = false;
      }
    },
    caseExpired: function(acc) {
      const res = confirm(
        `${this.addr} \n is expired \nWold you like to login`
      );
      if (res) {
        this.caseConnectWallet(acc);
      }
    },
    caseNotMatchAddr: function(acc) {
      const res = confirm(`session is expired \nWold you like to login`);
      if (res) {
        // this.isLogin = false;
        // this.hideSignup = false;
        this.caseConnectWallet(acc);
      }
    },
    caseConnectWallet: async function(acc) {
      if (typeof window.ethereum !== "undefined") {
        window.ethereum.eth_requestAccounts;
        const connectWallet = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        this.metaConnected = true;
        this.isLogin = true;

        //세션정보 저장
        SessionService.setItems({
          platform: this.selectedChainId,
          wallet: connectWallet[0],
          nickname: acc.nickname,
          avatar: acc.avatar,
        });
        this.$router.push("/");
        return connectWallet;
      }
    },
    caseLogined() {
      this.isLogin = true;
      this.hideSignup = true;
    },
    caseLoginedAndClick: function() {
      this.isLogin = true;
      this.hideSignup = true;
      this.$router.push("/mypage");
    },
    clickLogin: function() {
      OpenapiAuth.walletDoubleCheck(this.addr).then((hasAddr) => {
        //해당주소 가입여부 확인
        const account = hasAddr.data[0].account;
        if (account === null) {
          this.caseNotRegistered();
        } else {
          if (this.session.address === account) {
            if (SessionService.isExpired(this.session.lastTime)) {
              this.caseExpired(hasAddr.data[0]);
            } else {
              this.caseLoginedAndClick();
            }
          } else {
            this.caseNotMatchAddr(hasAddr.data[0]);
          }
        }
      });

      return;
    },
    saveAddr: function(addr) {
      this.$emit("saveAddr", addr);
    },
    onSignupColse: function() {
      this.hideSignup = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.nav__position {
  top: 4.2rem;
}

.clicked__border {
  border-color: $black;
  border-bottom-width: 2px;
}

.hover__market {
  &:hover {
    .market-dropdown {
      height: 7.5rem;
      border: 1px solid $gray-soft;
    }

    .hover__border {
      border-color: $gray-soft;
      border-bottom-width: 2px;
    }
  }

  .market-dropdown {
    $dropdown-item-height: 2.5rem;

    overflow: hidden;
    position: absolute;
    right: 13.5rem;
    width: 10rem;
    height: 0rem;

    border-radius: 0.5rem;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    transition-delay: 0.25s;

    // border: 1px solid $gray-soft;
    background-color: $white;

    .dropdown__item {
      display: flex;
      align-items: center;

      font-size: small;
      font-weight: 600;
      height: $dropdown-item-height;
      padding: 0.25rem 1rem;
      box-sizing: border-box;
      background-color: $white;

      &:hover {
        cursor: pointer;
        background-color: #20A97F;
        color: $white;
      }
    }
  }
}

#logo__text {
  display: inline-block;
}

.img__max-size {
  max-height: 60px;
}

.layout__header {
  height: 70px;
  margin: 0px 10%;
}

.layout__warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 94vw;
  background-color: #20A97F;
  color: white;
  font-weight: 600;
}

.layout__warning-center {
  height: 35px;
  width: 3vw;
  background-color: #20A97F;
}

.hover__bg__green:hover {
  background-color: #20A97F;
  color: white;
}
.hover__border-black:hover {
  border-color: $black;
  border-bottom-width: 2px;
}
.hover__border:hover {
  border-color: $gray-soft;
  border-bottom-width: 2px;
}

.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>
